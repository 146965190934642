import "./Style/App.css"
import Navbar from "./components/Navbar.jsx"
import About from "./components/About.js";
import News from "./components/News.js";
import Workers from "./components/Workers.js";
import Childreen from "./components/Childreen.js";
import Building from "./components/Building.js";
import Contact from "./components/Contact.jsx";
import Footer from "./components/Footer.jsx";
import { useEffect, useState } from "react"
import axios from "axios";

function App() {
   const [item, setitem] = useState([])
   const [item2, setitem2] = useState([])
   const [newsdata, setnews] = useState([])
   const [gal, setgal] = useState([])

   const fetchingData = async () => {
      let teachers = await axios.get("https://api.sergelikasbhunar.uz/api/teachers?append=imagePath&sort=-sort")
      setitem(teachers.data.data);
      let students = await axios.get("https://api.sergelikasbhunar.uz/api/students?append=imagePath&sort=-sort")
      setitem2(students.data.data);
      let posts = await axios.get("https://api.sergelikasbhunar.uz/api/posts?sort=-sort")
      setnews(posts.data.data);
      let galery = await axios.get("https://api.sergelikasbhunar.uz/api/galleries")
      setgal(galery.data.data);
   }
   useEffect(() => {
      fetchingData()
   }, [])
   return (
      <div className="App">
         <Navbar />
         <About />
         <News item={newsdata} />
         <Workers item={item} />
         <Building item={gal} />
         <Childreen item={item2} />
         <Contact />
         <Footer />
      </div>
   );
}


export default App;  
