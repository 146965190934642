import "../Style/App.css"
import "./Style/Footer.css"

function Footer() {
   return (
      <div className="Footer">
         <div className="container">
            <div className="content">
               <span className="logo">Sergreli Tuman Politexnikumi</span>
               <h1>Asosiy joylashgan joy</h1>
               <p>Yangi Qo'yliq ko'chasi, 50-uy</p>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d47993.88936727812!2d69.25703027557906!3d41.25187635698972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e6!4m0!4m3!3m2!1d41.251878!2d69.298329!5e0!3m2!1sen!2s!4v1733424834944!5m2!1sen!2s" width="600" height="450" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
            <a href="mailto:sergeli.kasbhunar@mail.ru">sergeli.kasbhunar@mail.ru</a>
         </div>
      </div>
   );
}
export default Footer;  