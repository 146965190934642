import "../Style/App.css"
import "./Style/News.css"

function News({ item }) {
   return (
      <div className="News" id="yangi">
         <div className="container">
            <h1 className="Prime_Text">Yangiliklar</h1>
            <div className="Cards">

               <div className="P__Card">
                  <img src={item[0]?.image} alt="" />
                  <div className="content">
                     <span className="Data__Text">{item[0]?.date}</span>
                     <h1>{item[0]?.title}</h1>
                     <div className="Secondary_TextsDiv" dangerouslySetInnerHTML={{ __html: item[0]?.body }}>

                     </div>

                  </div>
               </div>

               <div className="g_Card">
                  {
                     item.map((item, key) => {
                        if (key === 0) {
                           return null
                        } else {
                           return (
                              <div className="card">
                                 <img src={item.image} alt="" />
                                 <div className="content">
                                    <span className="Data__Text">{item.date}</span>
                                    <h1>{item.title}</h1>
                                    <div className="Secondary_TextsDiv" dangerouslySetInnerHTML={{ __html: item.body }}>

                                    </div>
                                 </div>
                              </div>
                           )
                        }
                     })
                  }

               </div>
            </div>
            <button className="btn_prime" onClick={(e) => { e.target.parentNode.children[1].children[1].style.display = "block" }}>Ko'proq ko'rish</button>
         </div>
      </div>
   );
}

export default News;  